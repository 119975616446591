import React from 'react';
import styled from '@emotion/styled';

import ListItem from './listItem';

const StyledRecentBlogPosts = styled.section`
  // max-width: ${props => props.theme.maxWidthText};
`;

const Heading = styled.h2`
`;

const RecentBlogPosts = ({ title='Recent Blog Posts', posts }) => {
  return (
    <StyledRecentBlogPosts>
      <Heading>{ title }</Heading>
      {posts.edges.map(post => {
        return <ListItem key={post.node.id} postNode={post.node} />;
      })}
    </StyledRecentBlogPosts>
  );
};

export default RecentBlogPosts

