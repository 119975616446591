import React from 'react';
import styled from '@emotion/styled';
// import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import { Layout, Wrapper } from 'components/layout';
import RecentBlogPosts from 'components/recentBlogPosts';
// import Image from '../components/image';
import SEO from '../components/seo';

const Hero = styled.div`
  max-width: 500px;
  padding-top: 1.666rem;
  padding: 3.332rem 0;
  margin-bottom: 2rem;
`;

const HeroSocialLinkList = styled.ul`
  display flex;
  list-style: none;
  margin: 0;

  > li {
    display: block;
    margin: 0;

    &:not(:last-of-type) {
      margin-right: 1rem;
    }

    a {
      font-size: 0.875rem;
      color: ${props => props.theme.colors.base};
      color: hsla(0, 0%, 0%, 0.8);
    }
  }
`;

const TwoColumnSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > div,
  > section {
    width: calc((100% - 3rem) * 0.5);
  }

  @media (max-width: 719px) {
    flex-direction: column;

    > div,
    > section {
      width: auto;
    }
  }
`;

const TechStack = styled.div`
  a {
    color: ${props => props.theme.colors.base};
    box-shadow: none;
  }

  @media (max-width: 719px) {
    margin-top: 3rem;
  }
`;

const TechStackTechonologiesGroup = styled.span`
  display:block;

  &:not(:first-of-type) {
    margin-top: 0.5rem;
  }
`;

export default ({ data }) => {
  const {
    site: {
      siteMetadata: {
        title,
        social,
      }
    },
    recentBlogPosts,
  } = data;

  return (
    <Layout showHeaderHomeLink={false}>
      <SEO
        path=""
        title={title}
        titleTemplate={null}
      />
      <Wrapper>
        <Hero>
          {/* <Img fixed={data.placeholderImage.childImageSharp.fixed} style={{borderRadius: '50%', float: 'right'}} /> */}
          <h1>Hi, I’m Gautam!</h1>
          <p>
            I'm a Software Engineer based in Bengaluru, India.
          </p>
          <p>
            As a Lead Engineer at <a href="https://www.upraised.co" target="_blank" rel="noopener noreferrer">Upraised</a>, 
            I'm helping people transition into Product Management. <br/>
            Previously, I led the front-end team at <a href="https://www.toppr.com/" target="_blank" rel="noopener noreferrer">Toppr</a>.
          </p>
          <p>
            When I'm not working, you can find me reading technical articles or science fiction, or watching a TV series.
          </p>
          <HeroSocialLinkList>
            <li>
              <a href={social.twitter.url} target="_blank" rel="noopener noreferrer">Twitter</a>
            </li>
            <li>
              <a href={social.github.url} target="_blank" rel="noopener noreferrer">Github</a>
            </li>
            <li>
              <a href={social.medium.url} target="_blank" rel="noopener noreferrer">Medium</a>
            </li>
            <li>
              <a href={social.linkedIn.url} target="_blank" rel="noopener noreferrer">LinkedIn</a>
            </li>
            <li>
              <a href={social.goodreads.url} target="_blank" rel="noopener noreferrer">Goodreads</a>
            </li>
          </HeroSocialLinkList>
        </Hero>

        <TwoColumnSection>
          <RecentBlogPosts
            title="Blog"
            posts={recentBlogPosts}
          />
          <TechStack>
            <h2>Tech Stack</h2>
            <TechStackTechonologiesGroup>
              <strong>Application:</strong> React, Redux, Styled Components, Node.js, Express, Python, Django.
            </TechStackTechonologiesGroup>

            <TechStackTechonologiesGroup>
              <strong>Data:</strong> PostgreSQL, DynamoDB, Redis, MongoDB.
            </TechStackTechonologiesGroup>

            <TechStackTechonologiesGroup>
              <strong>Infra:</strong> AWS, <OutboundLink href="https://m.do.co/c/75c2d8b0f45e" target="_blank" rel="noopener noreferrer">DigitalOcean</OutboundLink>, Kubernetes, EKS, Docker, Nginx.
            </TechStackTechonologiesGroup>
          </TechStack>
        </TwoColumnSection>
      </Wrapper>
    </Layout>
  );
};

export const pageQuery = graphql`
  query Home {
    site {
      siteMetadata {
        title,
        social {
          twitter {
            url
          }
          github {
            url
          }
          medium {
            url
          }
          linkedIn {
            url
          }
          goodreads {
            url
          }
        }
      }
    }
    placeholderImage: file(relativePath: { eq: "gautam-thumb.jpg" }) {
      childImageSharp {
        fixed(width: 120, height: 120, quality: 75) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    recentBlogPosts: allMarkdownRemark(
      limit: 3,
      filter: { fields: { visible: { eq: true } } }
      sort: { fields: [frontmatter___date] order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            excerpt
            url
          }
          fields {
            slug
            status
            visible
          }
        }
      }
    }
  }
`;
